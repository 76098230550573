import {
  CheckCircleIcon,
  ClockIcon,
  ExternalLinkIcon,
  VideoCameraIcon,
} from "@heroicons/react/outline";
import classNames from "classnames";
import React from "react";
import { useOutletContext } from "react-router-dom";

import { AppContext } from "../App";
import { AppointmentDetails } from "../components/AppointmentDetails";
import { PrimaryButton, SecondaryButton } from "../components/Button";
import Layout from "../components/Layout";
import { useCurrentDate, useSafeNavigate } from "../hooks";
import { useIntl } from "../locale";

const WAITING_ROOM_THRESHOLD_MINUTES = 10;

export const SummaryPage = () => {
  const currentTime = useCurrentDate();
  const navigate = useSafeNavigate();
  const intl = useIntl();
  const { apiData } = useOutletContext<AppContext>();

  const { scheduledAt, estimatedEndAt, doctor, externalCallUrl } =
    apiData.invitationState;
  if (!scheduledAt || !estimatedEndAt || !doctor) return <></>;

  const remainingMinutes = Math.ceil(
    Math.max(0, scheduledAt.getTime() - currentTime.getTime()) / (1000 * 60),
  );

  return (
    <Layout>
      <div className="flex flex-col max-w-lg w-full self-center flex-grow">
        <div className="flex-center space-x-3 sm:space-x-4 text-primary text-lg sm:text-xl font-medium">
          {remainingMinutes > WAITING_ROOM_THRESHOLD_MINUTES ? (
            <>
              <CheckCircleIcon className="h-10 sm:h-12" />
              <div>
                {intl({
                  en: "Your appointment was booked",
                  fr: "Votre rendez-vous a bien été confirmé",
                  pt: "Sua consulta foi marcada",
                })}
              </div>
            </>
          ) : remainingMinutes > 0 ? (
            <>
              <ClockIcon className="h-10 sm:h-12" />
              <div>
                {intl({
                  en: `Your appointment is in ${remainingMinutes} minutes`,
                  fr: `Votre rendez-vous commence dans ${remainingMinutes} minutes`,
                  pt: `Sua consulta começa em ${remainingMinutes} minutos`,
                })}
              </div>
            </>
          ) : (
            <>
              <VideoCameraIcon className="h-10 sm:h-12" />
              <div>
                {intl({
                  en: "Your appointment is starting",
                  fr: "Votre rendez-vous va commencer",
                  pt: "Sua consulta está começando",
                })}
              </div>
            </>
          )}
        </div>

        <AppointmentDetails
          startTime={scheduledAt}
          endTime={estimatedEndAt}
          patientFirstName={apiData.patientSummary.firstName}
          patientLastName={apiData.patientSummary.lastName}
          patientPhone={apiData.patientSummary.phone}
          doctorName={doctor.displayName}
          doctorTitle={doctor.title}
          doctorAvatarUrl={doctor.avatarUrl}
          className="my-8"
        />

        {remainingMinutes > WAITING_ROOM_THRESHOLD_MINUTES ? (
          <>
            <p className="text-text-light mb-9">
              {intl({
                en: "We will send you a reminder by email 10 minutes before your appointment.",
                fr: "Nous vous enverrons un rappel par email 10 minutes avant le début de votre rendez-vous.",
                pt: "Enviaremos um lembrete por e-mail 10 minutos antes da sua consulta.",
              })}
            </p>

            <SecondaryButton
              label={intl({
                en: "Cancel the appointment",
                fr: "Annuler le rendez-vous",
                pt: "Cancelar a consulta",
              })}
              onClick={() => navigate("cancel")}
            />
          </>
        ) : (
          <>
            <p className="text-text-light mb-9">
              {intl({
                en: `Please make sure you're in a quiet environment with a high-speed internet connection, then join the ${
                  remainingMinutes > 0 ? "waiting room" : "call"
                } to check your microphone and camera by using the button below.`,
                fr: `Assurez-vous d'être dans un environnement calme avec une connexion internet à haut débit, puis rejoignez ${
                  remainingMinutes > 0 ? "la salle d'attente" : "l'appel"
                } avec le bouton ci-dessous afin de vérifier le bon fonctionnement de votre microphone et de votre caméra.`,
                pt: `Certifique-se de estar em um ambiente silencioso com uma conexão de internet de alta velocidade e, em seguida, junte-se à ${
                  remainingMinutes > 0 ? "sala de espera" : "chamada"
                } para verificar seu microfone e câmera usando o botão abaixo.`,
              })}
            </p>

            {externalCallUrl ? (
              <a
                className={classNames(
                  "text-center items-center px-4 py-3 border border-transparent font-medium rounded-3xl text-white bg-primary/100 hover:bg-primary/75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary/40 disabled:bg-primary/50",
                  "sticky bottom-5 items-center align-middle",
                )}
                href={externalCallUrl}
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex-center">
                  <ExternalLinkIcon className="h-5 mr-2" />
                  {intl({
                    en: "Join the call",
                    fr: "Rejoindre l’appel",
                    pt: "Junte-se à chamada",
                  })}
                </div>
              </a>
            ) : (
              <PrimaryButton
                className="sticky bottom-5 items-center align-middle"
                label={
                  remainingMinutes > 0
                    ? intl({
                        en: "Join the waiting room",
                        fr: "Rejoindre la salle d’attente",
                        pt: "Junte-se à sala de espera",
                      })
                    : intl({
                        en: "Join the call",
                        fr: "Rejoindre l’appel",
                        pt: "Junte-se à chamada",
                      })
                }
                onClick={() => navigate("call")}
              />
            )}
          </>
        )}
      </div>
    </Layout>
  );
};

import { getStorageItem, url } from "./utils";

export const environment = () =>
  (process.env.REACT_APP_ENVIRONMENT as
    | "production"
    | "preprod"
    | "staging"
    | undefined) ?? "development";

export const backendUrl = () => {
  const customUrl = getStorageItem("BACKEND_URL");
  if (customUrl) return customUrl;

  switch (environment()) {
    case "production":
      return "https://api.nabla.com";
    case "preprod":
      return "https://api.preprod.nabla.com";
    case "staging":
      return "https://staging.api.iap2.nabla.com";
    case "development":
      return `http://${window.location.hostname}:3000/api`;
  }
};

export const appRedirectUrl = (returnUrl: string) =>
  url(`${backendUrl()}/app-redirect`, { return_to_full_url: returnUrl });

import { useEffect, useState } from "react";

import { appRedirectUrl, backendUrl, environment } from "./environment";
import { sendMessage } from "./iframe";
import {
  getTimezoneOffset,
  HexColor,
  plusDays,
  range,
  startOfDay,
  toDate,
} from "./utils";

async function needsIapRedirect() {
  if (environment() !== "staging") return false;

  try {
    const response = await fetch(`${backendUrl()}/health`, {
      method: "GET",
      credentials: "include",
    });
    return response.status !== 200;
  } catch (e) {
    return true;
  }
}

async function query(token: string, query: string, variables: object) {
  const response = await fetch(
    `${backendUrl()}/v1/webcall/graphql/authenticated`,
    {
      method: "POST",
      credentials: environment() !== "development" ? "include" : undefined,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    },
  );

  if (!response.ok) throw new Error(response.statusText);
  const state = await response.json();
  return state.data;
}

const MAIN_GRAPHQL_QUERY = `
fragment Doctor on PublicDoctor {
  uuid
  displayName
  avatarUrl
  title
}

query($slotsStartDate: DateTime, $slotsDays: Int!) {
  configuration {
    organizationName
    logoUrl
    faviconUrl
    theme {
      primaryColor
      textColor
      textLightColor
      textLighterColor
      dividerColor
      backgroundColor
      cardColor
    }
    confirmationConsents {
      firstConsentHtml
      secondConsentHtml
    }
  }
  patientSummary {
    locale
    firstName
    lastName
    phone
    email
  }
  invitationState {
    __typename

    ...on InvitationCreated {
      availableSlots(from: $slotsStartDate, days: $slotsDays) {
        from
        slots {
          start
          end
          doctor { ...Doctor }
        }
      }
    }

    ...on WebCallScheduled {
      scheduledAt
      estimatedEndAt
      livekitUrl
      livekitToken
      externalCallUrl
      doctor { ...Doctor }
    }

    ...on WebCallFinalized {
      scheduledAt
      finalizedAt
      doctor { ...Doctor }
      attachments {
        title
        fileUpload {
          urlV2 {
            url
          }
        }
      }
    }
  }
}
`;

const PICK_SLOT_QUERY = `
mutation($time: DateTime!, $timeZone: TimeZone!, $doctorUuid: UUID!) {
  pickSlot(time: $time, timeZone: $timeZone, doctorUuid: $doctorUuid)
}
`;

const CANCEL_SLOT_QUERY = `
mutation {
  cancelSlot
}
`;

export type Theme = {
  primaryColor: HexColor;
  textColor: HexColor;
  textLightColor: HexColor;
  textLighterColor: HexColor;
  dividerColor: HexColor;
  backgroundColor: HexColor;
  cardColor: HexColor;
};

export type ConfirmationConsents = {
  firstConsentHtml: String;
  secondConsentHtml: String;
};

type PublicDoctor = {
  uuid: string;
  displayName: string;
  avatarUrl: string | null;
  title: string | null;
};

type RawApiData = {
  configuration: {
    organizationName: string;
    logoUrl: string | null;
    faviconUrl: string | null;
    theme: Theme | null;
    confirmationConsents: ConfirmationConsents;
  };
  patientSummary: {
    locale: string;
    firstName: string;
    lastName: string;
    phone: string | null;
    email: string | null;
  };
  invitationState: {
    __typename:
      | "InvitationCreated"
      | "WebCallScheduled"
      | "WebCallCancelled"
      | "WebCallFinalized";
    availableSlots?: {
      from: string;
      slots: { start: string; end: string; doctor: PublicDoctor }[];
    };
    scheduledAt?: string;
    estimatedEndAt?: string;
    livekitUrl?: string;
    livekitToken?: string;
    doctor?: PublicDoctor;
    finalizedAt?: string;
    attachments?: { title: string; fileUpload: { urlV2: { url: string } } }[];
    externalCallUrl?: string;
  };
};

export type ApiState =
  | { status: "LOADING" }
  | { status: "ERROR" }
  | { status: "SUCCESS"; data: ApiData };

export type AvailableSlot = {
  start: Date;
  end: Date;
  doctor: PublicDoctor;
};

type AvailableSlotsForDay = {
  day: Date;
  slots: AvailableSlot[];
};

export type ApiData = Omit<RawApiData, "invitationState"> & {
  invitationState: Omit<
    RawApiData["invitationState"],
    "availableSlots" | "scheduledAt" | "estimatedEndAt" | "finalizedAt"
  > & {
    availableSlots?: AvailableSlotsForDay[];
    scheduledAt?: Date;
    estimatedEndAt?: Date;
    finalizedAt?: Date;
  };
};

export type ApiMutations = {
  pickSlot: (time: Date, doctorUuid: string) => void;
  cancelSlot: () => void;
};

export type ApiHookOutput = {
  apiState: ApiState;
  setSlotsStartDate: (day: Date) => void;
  mutations: ApiMutations;
};

const groupSlotsByDay = (
  slots: RawApiData["invitationState"]["availableSlots"],
): AvailableSlotsForDay[] | undefined => {
  if (!slots) return undefined;

  const currentDate = new Date();
  const startDay = startOfDay(new Date(slots.from));
  const allSlotsByDay = new Map<number, AvailableSlotsForDay>(
    range(7).map((i) => {
      const day = startOfDay(plusDays(startDay, i));
      return [day.getTime(), { day: day, slots: [] }];
    }),
  );
  slots.slots.forEach(({ start, end, doctor }) => {
    if (!start) return;
    const startDate = new Date(start);
    if (startDate < currentDate) return;
    allSlotsByDay
      .get(startOfDay(startDate).getTime())
      ?.slots?.push({ start: startDate, end: new Date(end), doctor });
  });

  return Array.from(allSlotsByDay.values());
};

const parseRawApiData = ({ invitationState, ...rest }: RawApiData): ApiData => {
  return {
    ...rest,
    invitationState: {
      ...invitationState,
      availableSlots: groupSlotsByDay(invitationState.availableSlots),
      scheduledAt: toDate(invitationState.scheduledAt),
      estimatedEndAt: toDate(invitationState.estimatedEndAt),
      finalizedAt: toDate(invitationState.finalizedAt),
    },
  };
};

export function useApi(token: string): ApiHookOutput {
  const [apiState, setApiState] = useState<ApiState>({ status: "LOADING" });

  const [slotsStartDate, setSlotsStartDate] = useState<Date | null>(null);

  const refreshApiState = () => {
    query(token, MAIN_GRAPHQL_QUERY, {
      slotsStartDate,
      slotsDays: 7,
    })
      .catch(() => setApiState({ status: "ERROR" }))
      .then((rawState: RawApiData) => {
        setApiState({
          status: "SUCCESS",
          data: parseRawApiData(rawState),
        });
      });
  };

  // Force users to log-in via IAP if needed.
  useEffect(() => {
    needsIapRedirect().then((doRedirect) => {
      if (doRedirect)
        window.location.href = appRedirectUrl(window.location.href);
    });
  }, []);

  useEffect(
    () => {
      refreshApiState();

      // TODO(@liautaud): Replace this with a proper GraphQL subscription.
      const interval = setInterval(refreshApiState, 5000);
      return () => clearInterval(interval);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token, slotsStartDate],
  );

  const mutations: ApiMutations = {
    pickSlot: (time, doctorUuid: string) => {
      query(token, PICK_SLOT_QUERY, {
        time,
        timeZone: getTimezoneOffset(),
        doctorUuid,
      }).then(() => {
        sendMessage({ event: "scheduled" });
        refreshApiState();
      });
    },
    cancelSlot: () => {
      query(token, CANCEL_SLOT_QUERY, {}).then(() => {
        sendMessage({ event: "cancelled" });
        refreshApiState();
      });
    },
  };

  return { apiState, setSlotsStartDate, mutations };
}
